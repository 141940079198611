import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './login/login.component';
import {ProjectsComponent} from './projects/projects.component';
import {AddProjectComponent} from './add-project/add-project.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { RegisterComponent } from './register/register.component';
import {TodoDetailsComponent} from './todo-details/todo-details.component';
import { TopicsComponent } from './topics/topics.component';
import { ProfileComponent } from './profile/profile.component';
import { PeopleComponent } from './people/people.component';
import { AttachmentsComponent } from './attachments/attachments.component';
import { TopicDetailsComponent } from './topic-details/topic-details.component';
import { ProjectInfoComponent } from './project-info/project-info.component';


const routes: Routes = [
  {
    path: 'projects',
    component: ProjectsComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'add-project',
    component: AddProjectComponent
  },
  {
    path: 'project/:id/todos',
    component: ProjectDetailsComponent
  },
  {
    path: 'project/:id',
    component: ProjectInfoComponent
  },
  {
    path: 'profile',
    component: ProfileComponent
  },
  {
    path: 'people',
    component: PeopleComponent
  },
  {
    path: 'project/:id/topics',
    component: TopicsComponent
  },
  {
    path: 'topic/:id',
    component: TopicDetailsComponent
  },
  {
    path: 'project/:id/attachment',
    component: AttachmentsComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'project/:pid/todo/:id',
    component: TodoDetailsComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
