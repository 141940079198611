import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findUserData'
})
export class FindUserDataPipe implements PipeTransform {

  transform(value: any, users: any[]): any {
    for(let count=0; count<users.length; count++){
      let user_id = users[count].id;
      if(user_id===value){
        return users[count].name;
      }
    }
    return 'imi';
  }

}
