import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _login_api = "https://basecamp.imicreation.com/admin/public/api/v1/login";
  private _register_api = "https://basecamp.imicreation.com/admin/public/api/v1/signup";
  private _logout_api = "https://basecamp.imicreation.com/admin/public/api/v1/logout";
  private _get_user = "https://basecamp.imicreation.com/admin/public/api/v1/profile";
  private _update_user = "https://basecamp.imicreation.com/admin/public/api/v1/updateUser";
  private _get_users = "https://basecamp.imicreation.com/admin/public/api/v1/get-users";
  private _permit_user = "https://basecamp.imicreation.com/admin/public/api/v1/permit-user";
  constructor(private http: HttpClient) { }

  registerUser(user) {
    return this.http.post<any>(this._register_api, user);
  }

  permitUser(data) {
    return this.http.post<any>(this._permit_user, data);
  }

  loginUser(user) {
    return this.http.post<any>(this._login_api, user);
  }

  logoutUser() {
    return this.http.get<any>(this._logout_api);
  }

  getProfile(){
    return this.http.get<any>(this._get_user);
  }

  getUsers(){
    return this.http.get<any>(this._get_users);
  }

  updateProfile(data){
    return this.http.post<any>(this._update_user, data)
  }
}
