import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormArray, FormControl} from '@angular/forms';
import {ProjectsService} from '../projects.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.css']
})
export class AddProjectComponent implements OnInit {

  form: FormGroup;
  ordersData = [];
  allUsers = [];
  formSubmitted = false;
  projectID = '';
  formValue = 'Create Project';
  constructor(private projectServ:ProjectsService, private _fb:FormBuilder, private router:Router) { }

  ngOnInit() {
    this.form = this._fb.group({
      orders: new FormArray([]),
      projectName: ['', Validators.required],
      projectDesc: ['']
    });
    this.fetch_users();
  }

  get f() {
    return this.form.controls
  }

  getControls() {
    return (this.form.get('orders') as FormArray).controls;
  }

  private addCheckboxes() {
    //console.log(this.ordersData);
    console.log(this.ordersData.length);
    this.ordersData.forEach((o, i) => {
      console.log(o);
      //const control = new FormControl(i === 0); // if first item set to true, else false
      let checked = false;
      //const control = new FormControl(checked); // If want to set value through web API
      const control = new FormControl(checked);
      (this.form.controls.orders as FormArray).push(control);
    });
  }

  fetch_users() {
    this.projectServ.get_users()
      .toPromise().then(data => {
        this.ordersData = data;
        this.addCheckboxes();
        //console.log('Promise resolved.')
      },
        err => console.log(err));
  }

  submit(event) {
    event.preventDefault();
    this.formSubmitted = true
    if (this.form.invalid) {
      return;
    }
    if (this.form.valid) {
      const selectedOrderIds = this.form.value.orders
        .map((v, i) => v ? this.ordersData[i].id : null)
        .filter(v => v !== null);
      const formValue = this.form.value;
      const send_project_details = { id: '', title: formValue.projectName, users: selectedOrderIds, siteurl:location.origin, description:formValue.projectDesc };
      if (this.projectID) {
        send_project_details.id = this.projectID;
        this.projectServ.update_project(send_project_details)
          .toPromise().then(data => {
            this.form.reset()
            this.formSubmitted = false
            this.projectID = ''
          })
      } else {
        this.projectServ.add_project(send_project_details)
          .toPromise().then(data => {
            this.form.reset()
            this.formSubmitted = false
            this.router.navigate(['/projects']);
          })
      }
    }
  }

}
