import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoginnavService {

  login_btn: boolean;
  logout_btn: boolean;
  token_store: string;
  constructor() {
    this.token_store = localStorage.getItem('improject_token');
    if (this.token_store !== '') {
      this.login_btn = false;
      this.logout_btn = true;
    } else {
      this.login_btn = true;
      this.logout_btn = false;
    }

  }
  hide() {
    this.login_btn = false;
    this.logout_btn = true;
  }
  show() {
    this.login_btn = true;
    this.logout_btn = false;
  }
}
