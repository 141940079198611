import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

  _project_view_api = "https://basecamp.imicreation.com/admin/public/api/v1/projects";
  _site_users = "https://basecamp.imicreation.com/admin/public/api/v1/user";
  _add_project = "https://basecamp.imicreation.com/admin/public/api/v1/add-project";
  _get_project = "https://basecamp.imicreation.com/admin/public/api/v1/project";
  _get_searched_projects = "https://basecamp.imicreation.com/admin/public/api/v1/search-projects";
  _update_project = "https://basecamp.imicreation.com/admin/public/api/v1/project";
  _remove_project = "https://basecamp.imicreation.com/admin/public/api/v1/remove-project";
  _set_favorite = "https://basecamp.imicreation.com/admin/public/api/v1/set-favorite";
  constructor(private http: HttpClient) { }

  get_projects() {
    return this.http.get<any>(this._project_view_api);
  }

  get_project(data) {
    return this.http.get<any>(this._get_project + '/' + data);
  }

  get_searched_projects(data) {
    return this.http.get<any>(this._get_searched_projects, {params:data});
  }

  get_users() {
    return this.http.get<any>(this._site_users);
  }

  add_project(data) {
    return this.http.post<any>(this._add_project, data);
  }

  update_project(data) {
    return this.http.put<any>(this._update_project + '/' + data.id, data);
  }

  remove_project(id){
    return this.http.delete<any>(this._remove_project+'/'+id)
  }

  set_favorite(data) {
    return this.http.post<any>(this._set_favorite, data);
  }
}
