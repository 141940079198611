import { Component, OnInit } from '@angular/core';
import { ListsService } from '../lists.service';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Http, ResponseContentType } from '@angular/http';
import { map } from 'rxjs/operators';
declare var tinymce:any;
declare var jQuery:any;
@Component({
  selector: 'app-todo-details',
  templateUrl: './todo-details.component.html',
  styleUrls: ['./todo-details.component.css']
})
export class TodoDetailsComponent implements OnInit {

  loading: boolean = false;
  discussionFormSubmitted = false;
  editTodoSubmitted = false;
  discussionForm: FormGroup;
  editDiscussionForm: FormGroup;
  editTodoForm: FormGroup;
  discussionButton: string = "Add";
  discussions: object = [];
  state:string = ''
  msg_show:string = ''
  discussionFile: File;
  editDiscussionFile:File;
  todoId: string = ''
  discussionDelete = ''
  discussionEditCancel = ''
  deleteTodost:number = 0
  commentId = '';
  list_title:string = '';
  list_date:string = '';
  list_author:string = '';
  fileUrl;
  editContent:string = '';
  projectName = '';
  projectId = '';
  users = {};
  loggedUser = {avatar:'', id:''};
  completed = false;
  completedClass = '';
  progress = [];
  constructor(private _fb: FormBuilder, private listServ: ListsService, private route: ActivatedRoute, private sanitizer: DomSanitizer, private http: Http, private router:Router) {
    this.discussionForm = this._fb.group({
      discussion: ['', Validators.required],
      avatar: new FormControl(null)
    })
    this.editDiscussionForm = this._fb.group({
      editdiscussion: ['', Validators.required],
      editAvatar: new FormControl(null)
    })
    this.editTodoForm = this._fb.group({
      editTodo: ['', Validators.required]
    })
  }

  get f() {
    return this.discussionForm.controls;
  }

  get ef() {
    return this.editTodoForm.controls;
  }

  editTodoEditor;
  addDiscussionEditor;
  editDiscussionEditor;

  ngAfterViewInit() {
    let setEditors = ["editTodo","addDiscussion","editDiscussion"];
    setEditors.forEach(nelement => {
      tinymce.init({
        selector: "#"+nelement,
        //plugins: ['link', 'table'],
        //skin_url: 'assets/skins/lightgray',
        setup: editor => {
          if(nelement=='editTodo'){
            this.editTodoEditor = editor;
          } else if(nelement=='addDiscussion'){
            this.addDiscussionEditor = editor;
          } else if(nelement=='editDiscussion'){
            this.editDiscussionEditor = editor;
          }
          
          editor.on('keyup change', () => {
            const content = editor.getContent();
            this.discussionForm.patchValue({discussion:content});
            this.editDiscussionForm.patchValue({editdiscussion:content});
            this.editTodoForm.patchValue({editTodo:content});
            //this.fieldData = content;
          });
        }
      });
    });
  }


  ngOnDestroy() {
    tinymce.remove(this.editTodoEditor);
    tinymce.remove(this.addDiscussionEditor);
    tinymce.remove(this.editDiscussionEditor);
  }

  ngOnInit() {
    this.todoId = this.route.snapshot.paramMap.get("id");
    this.getDiscussions();
  }

  enableModification(content, discussion_id){
    this.editDiscussionForm.patchValue({ editdiscussion: content });
    this.commentId = discussion_id;
    this.editDiscussionEditor.setContent(content);
  }

  submitDiscussion(event) {
    event.preventDefault();
    this.discussionFormSubmitted = true;
    if (this.discussionForm.invalid) {
      return;
    }
    const formModel = this.prepareSave();
    this.loading = true;
      this.listServ.add_discussion(formModel)
      .subscribe(
        res => {
          this.getDiscussions()
          this.discussionForm.reset;
          this.discussionForm.patchValue({discussion:''});
          this.editDiscussionForm.patchValue({editdiscussion:''});
          this.editTodoForm.patchValue({editTodo:''});
          this.clearAllContentEditor();
          this.discussionFormSubmitted = false;
        },
        err => console.log(err)
      )
  }

  clearAllContentEditor(){
    for(let i=0; i<tinymce.editors.length; i++){
       tinymce.editors[i].setContent("");
    }
   
   }

   startEditingTodo(todoName){
    //this.editTodoForm.patchValue({editTodo:todoName});
    tinymce.editors[0].setContent(todoName);
   }

   editTodo(event){
    event.preventDefault();
    this.editTodoSubmitted = true;
    if (this.editTodoForm.invalid) {
      return;
    }
    let input = new FormData();
    input.append('name', this.editTodoForm.get('editTodo').value);
    input.append('id', this.todoId);
    input.append('siteurl', location.origin)
    this.listServ.edit_todo({id:this.todoId, name:this.editTodoForm.get('editTodo').value})
    .toPromise().then(
      res=>{
        this.getDiscussions();
        this.discussionForm.patchValue({discussion:''});
        this.editDiscussionForm.patchValue({editdiscussion:''});
        this.editTodoForm.patchValue({editTodo:''});
        this.editTodoSubmitted = false;
      },
      err => console.log(err)
    )
   }

  updateDiscussion(event) {
    event.preventDefault();
    this.loading = true;
    let input = new FormData();
    input.append('content', this.editDiscussionForm.get('editdiscussion').value);
    input.append('avatar', this.editDiscussionFile);
    input.append('todo_id', this.todoId);
    input.append('id', this.commentId);
    input.append('siteurl', location.origin);
      this.listServ.edit_discussion(input)
        .toPromise().then(
          res => {
            this.getDiscussions()
            this.editDiscussionForm.reset();
            this.discussionForm.patchValue({discussion:''});
            this.editDiscussionForm.patchValue({editdiscussion:''});
            this.editTodoForm.patchValue({editTodo:''});
            jQuery('#teamhqr-comment--edit').modal('hide');
          },
          err => console.log(err)
      )
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      let file = event.target.files[0];
      this.discussionFile = file
    }
  }

  onFileChangeAvatar(event) {
    if (event.target.files.length > 0) {
      let file = event.target.files[0];
      this.editDiscussionFile = file
    }
  }

  private prepareSave(): any {
    let input = new FormData();
    input.append('content', this.discussionForm.get('discussion').value);
    input.append('avatar', this.discussionFile);
    input.append('todo_id', this.todoId);
    input.append('id', this.commentId)
    input.append('siteurl', location.origin)
    return input;
  }

  getDiscussions() {
    const sendData = { todo_id: this.todoId }
    this.listServ.get_discussions(sendData)
      .toPromise().then(data => {
        this.completedClass = '';
        this.discussions = data.comments;
        this.list_title = data.list_title;
        this.completed = data.status;
        if(data.status=='1'){
          this.completedClass = 'completed-todo';
        }
        this.list_date = data.list_date;
        this.list_author = data.list_author;
        this.state = data.state;
        this.msg_show = data.msg_show;
        this.projectName = data.project_name;
        this.projectId = data.project_id;
        this.users = data.users;
        this.loggedUser = data.logged_user;
        const status_progress = data.progress;
        this.progress = [];
        status_progress.forEach((element) => {
          let action_name = element.action;
          if(action_name.includes('open')){
            element.content = 're-opened this to-do.';
            element.iclass = 'delete';
          } else{
            element.content = 'completed this to-do.';
            element.iclass = 'check';
          }
          this.progress.push(element);
        });
        if(this.msg_show){
          this.discussionForm.disable()
        }
      },
        err => console.log(err))
  }

  downloadFile(fileset, fileput) {
    return this.http
      .get('https://basecamp.imicreation.com/admin/public/api/v1/dfiles/'+fileset, {
        responseType: ResponseContentType.Blob,
      }).pipe(
        map(res => {
          return {
            filename: fileput,
            data: res.blob()
          };
        }))
      .subscribe(res => {
        var url = window.URL.createObjectURL(res.data);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = res.filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
      }, error => {
        console.log('download error:', JSON.stringify(error));
      }, () => {
        //console.log('Completed file download.')
      });
  }

  deleteDiscussion(comment){
      this.listServ.remove_discussion(comment)
      .toPromise().then(
        data=>{
          this.getDiscussions()
        },
        err=>console.log(err)
      )
  }

  todoAction(event, id){
    if ( event.target.checked ) {
      this.listServ.change_todo_status({id:id, val:'1', project_id:this.projectId, siteurl:location.origin})
      .toPromise().then(
        data=>{
          this.getDiscussions();
        },
        err=>console.log(err)
      )
    } else{
      this.listServ.change_todo_status({id:id, val:'', project_id:this.projectId, siteurl:location.origin})
      .toPromise().then(
        data=>{
          this.getDiscussions();
        },
        err=>console.log(err)
      )
    }
  }

  RemoveTodo(todo){
    this.listServ.remove_todo(todo)
    .toPromise().then(
      data=>{
        this.router.navigate(['project/'+this.projectId+'/todos']);
      },
      err=>console.log(err)
    )
  }

}
