import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripP'
})
export class StripPPipe implements PipeTransform {

  transform(value: any): any {
    return value.replace(/(<p[^>]+?>|<p>|<\/p>)/img, "");
  }

}
