import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {AuthService} from '../auth.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  profileForm: FormGroup
  submitted = false
  profileUpdated = false
  userImage:File
  loadThumb = '';
  userName;
  constructor(private formBuilder: FormBuilder, private Auth:AuthService) { }

  ngOnInit() {
    this.profileForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      //lastName: ['', Validators.required],
      company: [''],
      //timeZone: [''],
      email: [{value:'', disabled:true}, [Validators.required, Validators.email]],
      oldPassword: [''],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
      avatar: new FormControl(null)
    }, { validator: [this.checkIfMatchingPasswords('password', 'confirmPassword'), this.validatePassword('oldPassword', 'password')] })
    this.getUser()
  }

  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      let passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true })
      }
      else {
        return passwordConfirmationInput.setErrors(null);
      }
    }
  }

  validatePassword(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      let passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== '' && passwordConfirmationInput.value === '') {
        return passwordConfirmationInput.setErrors({ notEquivalent: true })
      }
      else {
        return passwordConfirmationInput.setErrors(null);
      }
    }
  }

  get f() {
    return this.profileForm.controls
  }

  onSubmit() {
    this.submitted = true
    if (this.profileForm.invalid) {
      return;
    }
    //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.profileForm.value))
    let input = new FormData();
    input.append('name', this.profileForm.get('firstName').value);
    input.append('company', this.profileForm.get('company').value);
    input.append('thumb', this.userImage);
    if(this.profileForm.get('oldPassword').value!==''){
      input.append('old_pass', this.profileForm.get('oldPassword').value);
      input.append('password', this.profileForm.get('password').value);
    }
    
    this.Auth.updateProfile(input)
    .toPromise().then(
      data=>{
        this.profileUpdated = true
        this.loadThumb = data.file
      },
      err=>console.log(err)
    )
    console.log(input)
  }

  getUser(){
    this.Auth.getProfile()
      .toPromise().then(
        data=>
        {
          this.profileForm.patchValue({firstName: data.name});
          this.userName = data.name;
          this.profileForm.patchValue({email: data.email});
          this.loadThumb = data.avatar;
        },
        err=>console.log(err)
    )
  }
  
  onFileChange(event) {
    if (event.target.files.length > 0) {
      let file = event.target.files[0];
      this.userImage = file
    }
  }
}
