import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ListsService {

  _add_list = "https://basecamp.imicreation.com/admin/public/api/v1/add-list";
  _update_list = "https://basecamp.imicreation.com/admin/public/api/v1/update-list";
  _get_list = "https://basecamp.imicreation.com/admin/public/api/v1/get-list/";
  _add_todo = "https://basecamp.imicreation.com/admin/public/api/v1/add-todo";
  _edit_todo = "https://basecamp.imicreation.com/admin/public/api/v1/edit-todo";
  _add_discussion = "https://basecamp.imicreation.com/admin/public/api/v1/add-discussion";
  _edit_discussion = "https://basecamp.imicreation.com/admin/public/api/v1/edit-discussion";
  _get_discussions = "https://basecamp.imicreation.com/admin/public/api/v1/get-discussions";
  _get_file = "https://basecamp.imicreation.com/admin/public/api/v1/dfiles";
  _remove_list = "https://basecamp.imicreation.com/admin/public/api/v1/remove-list";
  _remove_todo = "https://basecamp.imicreation.com/admin/public/api/v1/remove-todo";
  _remove_discussion = "https://basecamp.imicreation.com/admin/public/api/v1/remove-discussion";
  _progress = "https://basecamp.imicreation.com/admin/public/api/v1/progress";
  _complete_todo = "https://basecamp.imicreation.com/admin/public/api/v1/todo-status";
  _addTopic = "https://basecamp.imicreation.com/admin/public/api/v1/add-topic";
  _get_topics = "https://basecamp.imicreation.com/admin/public/api/v1/get-topics";
  _get_topic = "https://basecamp.imicreation.com/admin/public/api/v1/get-topic";
  _editTopic = "https://basecamp.imicreation.com/admin/public/api/v1/edit-topic";
  _get_project = "https://basecamp.imicreation.com/admin/public/api/v1/get-project";
  _get_attachments = "https://basecamp.imicreation.com/admin/public/api/v1/get-attachments";
  _get_local_data = "https://basecamp.imicreation.com/admin/public/api/v1/get-localdata";
  _delete_project = "https://basecamp.imicreation.com/admin/public/api/v1/delete-project";
  constructor(private http: HttpClient) { }

  add_topic(data) {
    return this.http.post<any>(this._addTopic, data);
  }

  edit_topic(data) {
    return this.http.put<any>(this._editTopic+'/'+data.id, data);
  }

  add_list(data) {
    return this.http.post<any>(this._add_list, data);
  }

  update_list(data) {
    return this.http.put<any>(this._update_list + '/' + data.id, data);
  }

  get_list(data) {
    return this.http.get<any>(this._get_list, { params: data });
  }

  get_local_data() {
    return this.http.get<any>(this._get_local_data);
  }

  get_attachments(data) {
    return this.http.get<any>(this._get_attachments+'/'+data.id, data);
  }

  project_info(data) {
    return this.http.get<any>(this._get_project+'/'+data.id, data);
  }

  get_topics(data) {
    return this.http.get<any>(this._get_topics, { params: data });
  }

  get_topic(data) {
    return this.http.get<any>(this._get_topic, { params: data });
  }

  add_todo(data) {
    return this.http.post<any>(this._add_todo, data);
  }

  edit_todo(data) {
    return this.http.put<any>(this._edit_todo + '/' + data.id, data);
  }

  add_discussion(data) {
    return this.http.post<any>(this._add_discussion, data);
  }

  edit_discussion(data){
    return this.http.post<any>(this._edit_discussion, data)
  }

  get_discussions(data) {
    return this.http.get<any>(this._get_discussions, { params: data });
  }

  get_file(data) {
    return this.http.get<any>(this._get_file, { params: data });
  }

  remove_list(id){
    return this.http.delete<any>(this._remove_list+'/'+id)
  }

  delete_project(id){
    return this.http.delete<any>(this._delete_project+'/'+id)
  }

  remove_todo(id){
    return this.http.delete<any>(this._remove_todo+'/'+id)
  }

  remove_discussion(id){
    return this.http.delete<any>(this._remove_discussion+'/'+id)
  }

  get_progress(data){
    return this.http.get<any>(this._progress, { params: data })
  }

  change_todo_status(data){
    return this.http.post<any>(this._complete_todo, data)
  }
}
