import { Component, OnInit, OnDestroy } from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../auth.service';
import {ListsService} from '../lists.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
declare var jQuery:any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  user = {avatar:''};
  notificationView;
  notificationExists = false;
  searchForm:FormGroup;
  searchSubmitted = false;
  constructor(private authServ:AuthService, private listServ:ListsService, private _route:Router, private _fb:FormBuilder) { 
    this.searchForm = this._fb.group({
      searchField:['', Validators.required]
    })
  }

  get s(){
    return this.searchForm.controls;
  }

  searchSubmit(){
    this.searchSubmitted = true;
    if(this.searchForm.invalid){
      return;
    }
    let searchField = this.searchForm.get('searchField').value;
    this._route.navigate(['/projects', {search:searchField}]);
  }

  ngOnInit() {
    this.authServ.getProfile()
    .toPromise().then(data=>{
      this.user = data;
    });
    jQuery(window).on('load', function(){
      //localStorage.clear();
    });
    jQuery(window).on("beforeunload", function() { 
      localStorage.removeItem('projects'); 
    });
    setInterval(function(){
      localStorage.removeItem('projects');
    }, 300000);
    const projects = localStorage.getItem('projects');
    if(!projects){
      this.getLocalData();
    }
  }

  ngOnDestroy(){
    //localStorage.clear();
  }

  notificationReset(){
    this.notificationView = '1';
  }

  newNotification(data){
    this.notificationExists = data;
  }

  getLocalData(){
    this.listServ.get_local_data()
    .toPromise().then(
      data=>{
        let projects = data.projects;
        projects.forEach((element) => {
          //let project_users = element[0]['users'];
          //let project_details = element[0];
        });
        localStorage.setItem('projects', JSON.stringify(projects));
      },
      err=>console.log(err)
    )
  }

  logoutUser(){
    localStorage.removeItem('improject_token');
    this._route.navigate(['/login']);
  }

}
