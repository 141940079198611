import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
//npm install @angular/http@latest
import { HttpModule } from '@angular/http';

//npm install time-ago-pipe --save
import {TimeAgoPipe} from 'time-ago-pipe';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ProjectsComponent } from './projects/projects.component';
import { AuthService } from './auth.service';
import { LoaderService } from './LoaderService';
import { TokenInterceptorService } from './token-interceptor.service';
import { LoginComponent } from './login/login.component';
import { AddProjectComponent } from './add-project/add-project.component';
import { FistLetterPipe } from './fist-letter.pipe';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import {RegisterComponent} from './register/register.component';
import {AddtodoComponent} from './project-details/addtodo/addtodo.component';
import { AddlistComponent } from './project-details/addlist/addlist.component';
import {TodoDetailsComponent} from './todo-details/todo-details.component';
import { UpdateListComponent } from './project-details/update-list/update-list.component';
import { TopicsComponent } from './topics/topics.component';
import { AttachmentsComponent } from './attachments/attachments.component';
import {ProfileComponent} from './profile/profile.component';
import { PeopleComponent } from './people/people.component';
import { SelectUserProjectPipe } from './select-user-project.pipe';
import { FindUserDataPipe } from './find-user-data.pipe';
import { GetUserAvatarPipe } from './get-user-avatar.pipe';
import { TopicDetailsComponent } from './topic-details/topic-details.component';
import { ProjectInfoComponent } from './project-info/project-info.component';
import { StripHTMLPipe } from './strip-html.pipe';
import { StripPPipe } from './strip-p.pipe';
import { MakeLinkPipe } from './make-link.pipe';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    NotificationsComponent,
    ProjectsComponent,
    LoginComponent,
    AddProjectComponent,
    FistLetterPipe,
    ProjectDetailsComponent,
    RegisterComponent,
    AddtodoComponent,
    AddlistComponent,
    TodoDetailsComponent,
    TimeAgoPipe,
    UpdateListComponent,
    TopicsComponent,
    AttachmentsComponent,
    ProfileComponent,
    PeopleComponent,
    SelectUserProjectPipe,
    FindUserDataPipe,
    GetUserAvatarPipe,
    TopicDetailsComponent,
    ProjectInfoComponent,
    StripHTMLPipe,
    StripPPipe,
    MakeLinkPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpModule
  ],
  providers: [AuthService, LoaderService,{
    provide:HTTP_INTERCEPTORS,
    useClass:TokenInterceptorService,
    multi:true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
