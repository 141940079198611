import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import {ListsService} from '../lists.service';
declare var jQuery:any;

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  @Input() notificationView = '0';
  @Output() newNotification = new EventEmitter<boolean>();
  interval;
  activities;
  users;
  count;
  constructor(private listServ:ListsService) { }

  ngOnInit() {
    this.loadActivity(0);
    this.interval = setInterval(() => { 
      this.loadActivity(0); 
    }, 90000);
  }

  loadActivity(reset){
    this.listServ.get_progress({reset:reset})
    .toPromise().then(
      data=>{
        this.activities = data.activities;
        this.users = data.users;
        this.count = data.count;
        if(data.count>0){
          this.newNotification.emit(true);
        }
        if(data.notification==1){
          this.loadActivity(1);
          localStorage.removeItem('projects');
        }
      },
      err=>console.log(err)
    )
  }

}
