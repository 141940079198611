import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { finalize, tap, catchError } from 'rxjs/operators';
import { LoaderService } from './LoaderService';
import { Router } from '@angular/router';
import { delay } from 'q';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private loaderService: LoaderService, private _router: Router) { }

  show(){
    document.getElementById("spinner-front").classList.add("show");
    document.getElementById("spinner-back").classList.add("show");
  }
  hide(){
    document.getElementById("spinner-front").classList.remove("show");
    document.getElementById("spinner-back").classList.remove("show");
  }

  intercept(req, next) {
    if(req.url!='https://basecamp.imicreation.com/admin/public/api/v1/progress'){
      this.show();
    }
    
    const loaderService = this.loaderService.isLoading;
    loaderService.next(true);
    let tokenizedReq = req.clone({
      setHeaders: {
        Authorization: "Bearer " + localStorage.getItem('improject_token')
      }
    })
    return next.handle(tokenizedReq)
      .pipe(catchError((err: any) => {
        //console.log('this log isn't')
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401 || err.status === 0) {
            //console.log('Unauthorized');
            let referer = this._router.url;
            this._router.navigate(['/login', {referer:referer}])
            return throwError(err);
          }
          this.hide();
        }

        return new Observable<HttpEvent<any>>();
      }),
        finalize(() => {
          loaderService.next(false);
          this.hide();
        })
          
      )
  }
}
