import { Component, OnInit, OnChanges } from '@angular/core';
import { ProjectsService } from '../projects.service';
import { ListsService } from '../lists.service';
import { ActivatedRoute } from '@angular/router';

declare var jQuery:any;

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.css']
})
export class ProjectDetailsComponent implements OnInit {

  fetchTodoList = [{ id: '', name: '' }];
  projectId = null;
  todoData: any;
  listData: any;
  projectData: object = {id:''};
  projectLists: object;
  todoSaved = '';
  projectTitle = '';
  listId = '';
  listContent = '';
  completed_todos = [];
  opened_todos = [];
  totalCompleted = 0;
  constructor(private _projectServ: ProjectsService, private route: ActivatedRoute, private listServ: ListsService) { }

  ngOnInit() {
    this.projectId = this.route.snapshot.paramMap.get("id"); //Get ID from URL, id is defined in app.routing file
    //this.getProjectLists()
    this.getProjectWithValidation();
    
  }

  getProjectWithValidation() {
    /*const projects = '';
    if(projects){
      let projects_get = JSON.parse(projects);
      projects_get.forEach((element) => {
        if(element.id!=this.projectId){
          return true;
        }
        this.projectData = element;
        let projectList:any = element.list;
        var create_list = [];
        
        for(let list_c=0; list_c<projectList; list_c++){
          let project_todos = projectList[list_c]['todos'];
          this.completed_todos = [];
          this.opened_todos = [];
          let this_todo_list = projectList[list_c];
          //this.projectLists
          for(let list_count=0; list_count<project_todos.length; list_count++){
            if(project_todos[list_count]['status']=='1'){
              this.totalCompleted++;
              this.completed_todos.push(project_todos[list_count]);
              this.completed_todos.sort(this.compare_dates);
              this_todo_list.todos_completed = this.completed_todos;
            } else{
              this.opened_todos.push(project_todos[list_count]);
              this_todo_list.todos_opened = this.opened_todos;
            }
          }
          create_list.push(this_todo_list);
        }
        this.projectLists = create_list;
      });
      jQuery('body').trigger('click');
    } else{*/
      this._projectServ.get_project(this.projectId)
      .toPromise().then(data => {
        this.projectData = data;
        //this.projectLists = data.list;
        let projectList:any = data.list;
        var create_list = [];
        for(let list_c=0; list_c<projectList.length; list_c++){
          let project_todos = projectList[list_c]['todos'];
          this.completed_todos = [];
          this.opened_todos = [];
          let this_todo_list = projectList[list_c];
          //this.projectLists
          for(let list_count=0; list_count<project_todos.length; list_count++){
            if(project_todos[list_count]['status']=='1'){
              this.totalCompleted++;
              this.completed_todos.push(project_todos[list_count]);
              this.completed_todos.sort(this.compare_dates);
              this_todo_list.todos_completed = this.completed_todos;
            } else{
              this.opened_todos.push(project_todos[list_count]);
              this_todo_list.todos_opened = this.opened_todos;
            }
          }
          create_list.push(this_todo_list);
        }
        this.projectLists = create_list;
      });
      jQuery('body').trigger('click');
    //}
    
  }

  compare_dates(date1,date2){

    let d1= new Date(date1['completed_on']);
    let d2= new Date(date2['completed_on']);
    if (d1<d2) return 1;
     else if (d1>d2)  return -1;
     else return 0;
  }

  editTodo(event, todo_id, todo_name, list_id) {
    this.todoData = { todo_id: todo_id, todo_name: todo_name, list_id: list_id }
  }

  editList(event, list_id, list_name, list_access) {
    this.listData = { id: list_id, title: list_name, access: list_access }
  }

  todoAction(event, id){
    if ( event.target.checked ) {
      this.listServ.change_todo_status({id:id, val:'1', project_id:this.projectId, siteurl:location.origin})
      .toPromise().then(
        data=>{
          localStorage.removeItem('projects');
          this.getProjectWithValidation();
          this.totalCompleted++;
        },
        err=>console.log(err)
      )
    } else{
      this.listServ.change_todo_status({id:id, val:'', project_id:this.projectId, siteurl:location.origin})
      .toPromise().then(
        data=>{
          localStorage.removeItem('projects'); 
          this.getProjectWithValidation();
          this.totalCompleted--;
        },
        err=>console.log(err)
      )
    }
  }

  todoUpdate(data){
    localStorage.removeItem('projects');
    this.getProjectWithValidation();
  }

  newListAdded(data){
    localStorage.removeItem('projects');
    this.getProjectWithValidation();
  }

  updateList(id, content){
    this.listId = id;
    this.listContent = content;
  }

  listUpdated(){
    localStorage.removeItem('projects');
    this.getProjectWithValidation();
  }

}
