import { Component, OnInit, EventEmitter, Input, Output, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { ProjectsService } from '../../projects.service';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { ListsService } from '../../lists.service';

@Component({
  selector: 'app-update-list',
  templateUrl: './update-list.component.html',
  styleUrls: ['./update-list.component.css']
})
export class UpdateListComponent implements OnInit, OnChanges {

  @Input() listId:number;
  @Input() listContent:string;

  listUpdateForm: FormGroup;
  listFormSubmitted = false;
  projectId = null;
  @Output() listUpdated:EventEmitter<number> = new EventEmitter<number>();
  constructor(private projectSer: ProjectsService, private _fb: FormBuilder, private route: ActivatedRoute, private listServ: ListsService) {
    this.listUpdateForm = this._fb.group({
      todoListName: [this.listContent, Validators.required],
    })
    
   }

   get f() {
    return this.listUpdateForm.controls
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.listContent && typeof changes.listContent.currentValue !== 'undefined') {
      const listContent: SimpleChange = changes.listContent
      this.listContent = listContent.currentValue
      this.listUpdateForm.patchValue({todoListName:this.listContent});
    }
  }

  ngOnInit() {
    this.projectId = this.route.snapshot.paramMap.get("id"); //Get ID from URL, id is defined in app.routing file
  }

  submitList(event) {
    event.preventDefault();
    this.listFormSubmitted = true
    if (this.listUpdateForm.invalid) {
      return;
    }
    if (this.listUpdateForm.valid) {
      //console.log(selectedOrderIds);
      const formValue = this.listUpdateForm.value;
      //console.log(formValue);
      const send_list_details = { project_id: this.projectId, title: formValue.todoListName, id: this.listId, siteurl:location.origin };
        this.listServ.update_list(send_list_details)
          .toPromise().then(data => {
            this.listUpdated.emit(1);
            let hideListForm:HTMLElement = document.getElementById("closeUpdateList") as HTMLElement;
            hideListForm.click();
            //this.listUpdateForm.reset();
          })
    }
  }

  deleteList(){
    this.listServ.remove_list(this.listId)
      .toPromise().then(
        data=>{
          this.listUpdated.emit(1);
        },
        err=>console.log(err)
      )
  }

}
