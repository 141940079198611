import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ProjectsService} from '../projects.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {

  projects;
  state:string = '';
  msg_show:string = '';
  chatItems = [];
  constructor(private projectserv:ProjectsService, private route:ActivatedRoute) { 
    route.params.subscribe(params => {
      let searchValue = params['search'];
      if(typeof searchValue !== 'undefined' && searchValue){
        console.log(searchValue);
        this.projectserv.get_searched_projects({search:searchValue})
        .toPromise().then(data => {
          if(data){
            this.projects = data;
          } else{
            this.get_projects(true);
          }
        },
          err => console.log("There is some error"));
      } else{
          this.get_projects(true);
      }
    })
  }

  ngOnInit() {
    const projects = localStorage.getItem('projects');
      if(projects){
        this.projects = JSON.parse(projects);
      } else{
        this.get_projects(true);
      }
    
    
  }

  get_projects(data) {
    /*this.projectserv.get_projects()
      .subscribe(
        res => {
          this.projects = res
          console.log(res)
        },
        err => console.log(err)
      )*/
    //console.log("Hi");

    this.projectserv.get_projects()
      .toPromise().then(data => {
        this.projects = data;
        //localStorage.setItem('projects', JSON.stringify(data));
      },
        err => console.log("There is some error"));
  }

  talkBack(e: object) {
    this.projects = e;
  }

  setProjectFavorite(projectId, status){
    this.projectserv.set_favorite({projectId:projectId, status:status})
      .toPromise().then(data => {
        this.get_projects(true);
        //console.log('Promise resolved.')
      },
        err => console.log("There is some error"));
  }

}
