import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import {AuthService} from '../auth.service';

@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.css']
})
export class PeopleComponent implements OnInit {

  users;
  projects:object;
  assigned;
  form: FormGroup;
  currentUser;
  currentUserName;
  constructor(private authServ:AuthService, private fb: FormBuilder) { }

  ngOnInit() {
    this.fetchUsers();
    this.form = this.fb.group({
      name: this.fb.array([])
    });
  }

  loadAssignedProjects(userId){
    let users = this.users;
    let total_users = users.length;
    for(let count=0; count<=total_users; count++){
      let thisUserId = users[count]['id'];
      this.currentUser = userId;
      if(userId===thisUserId){
        this.currentUserName = users[count]['name'];
        this.assigned = users[count].projects;
      }
    }
  }

  onChange(id: string, isChecked: boolean) {
    if (isChecked) {
      this.assigned.push(id);
    } else {
      var index = this.assigned.indexOf(id);
      if (index > -1) {
        this.assigned.splice(index, 1);
      }
    }
  }

  submit() {
    this.authServ.permitUser({projects:this.assigned, user:this.currentUser})
    .toPromise().then(data=>{
      this.users = data.users;
      this.projects = data.projects;
      this.fetchUsers();
    })
  }

  fetchUsers(){
    this.authServ.getUsers()
    .toPromise().then(data=>{
      this.users = data.users;
      this.projects = data.projects;
    })
  }

}
