import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'selectUserProject'
})
export class SelectUserProjectPipe implements PipeTransform {

  transform(value: any, assigned:any): any {
    if(assigned && assigned.includes(value)){
      return true;
    } else{
      return false;
    }
  }

}
