import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { LoaderService } from '../LoaderService';
import {FormBuilder, FormGroup, Validators, FormArray, FormControl} from '@angular/forms';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  registerForm:FormGroup
  registerSubmitted = false
  registrationMsg = { class: '', msg: '' };
  registerUserData = {};
  constructor(private _auth: AuthService, public loaderService: LoaderService, private _fb:FormBuilder) { 
    this.registerForm = this._fb.group({
      registerName: ['', Validators.required],
      registerEmail:['', [Validators.required, Validators.email]],
      registerPassword:['', [Validators.required, Validators.minLength(6)]],
      registerCnfPassword:['', Validators.required]
    }, { validator: this.checkIfMatchingPasswords('registerPassword', 'registerCnfPassword')})
  }

  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      let passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true })
      }
      else {
        return passwordConfirmationInput.setErrors(null);
      }
    }
  }

  get f() {
    return this.registerForm.controls
  }

  ngOnInit() {
  }

  registerUser() {
    this.registerSubmitted = true
    if (this.registerForm.invalid) {
      return;
    }
    let input = new FormData();
    input.append('name', this.registerForm.get('registerName').value);
    input.append('email', this.registerForm.get('registerEmail').value);
    input.append('password', this.registerForm.get('registerPassword').value);
    input.append('password_confirmation', this.registerForm.get('registerCnfPassword').value);
    this._auth.registerUser(input)
      .subscribe(
        res => {
          this.registrationMsg = { class: 'success', msg: res.message };
          this.registerForm.reset();
        },
        err => {
          let errors = '';
          console.log(err);
          for (const [key, value] of Object.entries(err.error.errors)) {
            errors += '<p>' + key + ': ' + value + '</p>';
          }
          this.registrationMsg = { class: 'danger', msg: errors };
        }
      )
  }

}
