import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'makeLink'
})
export class MakeLinkPipe implements PipeTransform {

  transform(value: any): any {
    return value.innerHTML.replace(/(http:\/\/[^\s]+)/g, "<a href='$1'>$1</a>");
  }

}
