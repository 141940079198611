import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { ProjectsService } from '../../projects.service';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { ListsService } from '../../lists.service';

@Component({
  selector: 'app-addtodo',
  templateUrl: './addtodo.component.html',
  styleUrls: ['./addtodo.component.css']
})
export class AddtodoComponent implements OnInit {
  

  @Output() listAdded: EventEmitter<string> = new EventEmitter<string>();

  todoListForm: FormGroup;
  projectId = null;
  listId = '';
  listFormSubmitted = false;
  clicked = false;
  constructor(private projectSer: ProjectsService, private _fb: FormBuilder, private route: ActivatedRoute, private listServ: ListsService) {
    this.todoListForm = this._fb.group({
      todoListName: ['', Validators.required],
      users: new FormArray([]),
    })
  }

  get f() {
    return this.todoListForm.controls
  }

  ngOnInit() {
    this.projectId = this.route.snapshot.paramMap.get("id"); //Get ID from URL, id is defined in app.routing file
  }

  submitList(event) {
    event.preventDefault();
    this.listFormSubmitted = true
    if (this.todoListForm.invalid) {
      return;
    }
    if (this.todoListForm.valid) {
      this.clicked = true;
      //console.log(selectedOrderIds);
      const formValue = this.todoListForm.value;
      //console.log(formValue);
      const send_list_details = { project_id: this.projectId, title: formValue.todoListName, id: this.listId, siteurl:location.origin };
      if (this.projectId && this.listId==='') {
        this.listServ.add_list(send_list_details)
          .toPromise().then(data => {
            this.listAdded.emit('1');
            this.todoListForm.reset();
            let hideListForm:HTMLElement = document.getElementById("hideListForm") as HTMLElement;
            hideListForm.click();
            this.clicked = false;
          })
      } else if (this.projectId && this.listId!=='') {
        this.listServ.update_list(send_list_details)
          .toPromise().then(data => {
            this.listAdded.emit('1');
            this.todoListForm.reset();
            this.clicked = false;
          })
      }
    }
  }

  deleteList(){
      this.listServ.remove_list(this.listId)
      .toPromise().then(
        data=>{
          
        },
        err=>console.log(err)
      )
  }

}
