import { Component, OnInit } from '@angular/core';
import {ListsService} from '../lists.service';
import { ActivatedRoute, Router } from '@angular/router';
import {ProjectsService} from '../projects.service';


@Component({
  selector: 'app-project-info',
  templateUrl: './project-info.component.html',
  styleUrls: ['./project-info.component.css']
})
export class ProjectInfoComponent implements OnInit {

  progress;
  users;
  project = {id:''};
  projectId;
  projectActive = '';
  projects;
  constructor(private listServ:ListsService, private route: ActivatedRoute, private proServ:ProjectsService, private _router:Router) { }

  ngOnInit() {
    this.projectId = this.route.snapshot.paramMap.get("id");
    /*const projects = localStorage.getItem('projects');
    if(projects){
      this.projects = JSON.parse(projects);
      let projects_get = JSON.parse(projects);
      projects_get.forEach((element) => {
        if(element.id!=this.projectId){
          return true;
        }
        this.project = element;
        //this.projectLists = element.list;
      })
    } else{
      this.projectInfo();
    }*/

    this.projectInfoOld();
  }

  deleteProject(){
    this.listServ.delete_project(this.projectId)
    .toPromise().then(
      data=>{
        this._router.navigate(['/projects']);
      },
      err=>console.log(err)
    )
  }

  projectInfoOld(){
    this.listServ.project_info({id:this.projectId})
    .toPromise().then(
      data=>{
        this.project = data['project'];
        this.progress = data['notifications'];
        this.users = data['users'];
        if(data['project']['favorite']=='1'){
          this.projectActive = 'active';
        } else{
          this.projectActive = '';
        }
      },
      err=>console.log(err)
    )
  }

  projectInfo(){
    this.listServ.project_info({id:this.projectId})
    .subscribe((data)=>{
      this.project = data['project'];
        this.progress = data['notifications'];
        this.users = data['users'];
        if(data['project']['favorite']=='1'){
          this.projectActive = 'active';
        } else{
          this.projectActive = '';
        }
    })
  }

  projectFavorite(){
    let status = (this.projectActive=='active')?'disable':'enable';
    this.proServ.set_favorite({projectId:this.projectId, status:status})
    .toPromise().then(
      data=>{
        this.projectInfo();
      },
      err=>console.log(err)
    )
  }

}
