import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { ProjectsService } from '../../projects.service';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { ListsService } from '../../lists.service';
declare var tinymce:any;

@Component({
  selector: 'app-addlist',
  templateUrl: './addlist.component.html',
  styleUrls: ['./addlist.component.css']
})
export class AddlistComponent implements OnInit {
  
  @Input() listId = '';
  @Output() todoSaved = new  EventEmitter<string>();
  todoForm: FormGroup;
  projectId = null;
  todoId = '';
  fieldData;
  @Output() todoUpdate = new EventEmitter();
  todoFormSubmitted = false
  constructor(private projectSer: ProjectsService, private _fb: FormBuilder, private route: ActivatedRoute, private listServ: ListsService) {
    this.todoForm = this._fb.group({
      todoList: [this.listId],
      todoName: ['', Validators.required],
    })
  }

  get ft() {
    return this.todoForm.controls
  }

  ngOnInit() {
    this.projectId = this.route.snapshot.paramMap.get("id"); //Get ID from URL, id is defined in app.routing file
  }

  editor;

  ngAfterViewInit() {
    tinymce.init({
      selector: "#todo-"+this.listId,
      //plugins: ['link', 'table'],
      //skin_url: 'assets/skins/lightgray',
      setup: editor => {
        this.editor = editor;
        editor.on('keyup change', () => {
          const content = editor.getContent();
          this.todoForm.patchValue({todoName:content});
          this.fieldData = content;
        });
      }
    });
  }


  ngOnDestroy() {
    tinymce.remove(this.editor);
  }

  submitTodo(event) {
    event.preventDefault();
    this.todoFormSubmitted = true;
    if (this.todoForm.invalid) {
      return;
    }
    if (this.todoForm.valid) {
      const formValue = this.todoForm.value;
      const send_list_details = { project_id: this.projectId, title: this.fieldData, list_id: this.listId, id: '', siteurl:location.origin };
      if (this.projectId && this.todoId === '') {
        this.listServ.add_todo(send_list_details)
          .toPromise().then(data => {
            this.todoUpdate.emit('1');
            tinymce.remove(this.editor);
            this.todoForm.reset;
          })
      } else if (this.todoId !== '') {
        this.listServ.edit_todo(send_list_details)
          .toPromise().then(data => {
            this.todoUpdate.emit('1');
            tinymce.remove(this.editor);
            this.todoForm.reset;
          })
      }
    }

  }

}
