import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import { Http, ResponseContentType } from '@angular/http';
import { map } from 'rxjs/operators';
import {ListsService} from '../lists.service';
declare var tinymce:any;
declare var jQuery:any;

@Component({
  selector: 'app-topic-details',
  templateUrl: './topic-details.component.html',
  styleUrls: ['./topic-details.component.css']
})
export class TopicDetailsComponent implements OnInit {

  projectId = '';
  editTopicForm:FormGroup;
  editTopicFormSubmitted = false;
  editCommentForm:FormGroup;
  editCommentFormSubmitted = false;
  addCommentForm:FormGroup;
  addCommentFormSubmitted = false;
  topic:object = [];
  project;
  discussionFile: File;
  editDiscussionFile:File;
  users;
  topicId;
  commentId = '';
  discussions;
  constructor(private route:ActivatedRoute, private listServ:ListsService, private _fb:FormBuilder, private http: Http) { 
    this.editTopicForm = this._fb.group({
      topicName: ['', Validators.required],
      topicContent: ['']
    })
    this.editCommentForm = this._fb.group({
      editdiscussion: ['', Validators.required],
      editAvatar: new FormControl(null)
    })
    this.addCommentForm = this._fb.group({
      discussion: ['', Validators.required],
      avatar: new FormControl(null)
    })
  }

  updateDiscussion(event) {
    event.preventDefault();
    let input = new FormData();
    input.append('content', this.editCommentForm.get('editdiscussion').value);
    input.append('avatar', this.editDiscussionFile);
    input.append('todo_id', this.topicId);
    input.append('id', this.commentId);
    input.append('siteurl', location.origin);
      this.listServ.edit_discussion(input)
        .toPromise().then(
          res => {
            this.getTopic()
            this.editCommentForm.reset();
            this.addCommentForm.patchValue({discussion:''});
            this.editCommentForm.patchValue({editdiscussion:''});
            this.editTopicForm.patchValue({editTodo:''});
            jQuery('#teamhqr-comment--edit').modal('hide');
          },
          err => console.log(err)
      )
  }

  ngOnInit() {
    this.topicId = this.route.snapshot.paramMap.get("id"); //Get ID from URL, id is defined in app.routing file
    this.getTopic();
  }

  get et(){
    return this.editTopicForm.controls;
  }

  get ec(){
    return this.editCommentForm.controls;
  }

  get ac(){
    return this.addCommentForm.controls;
  }

  private prepareSave(): any {
    let input = new FormData();
    input.append('content', this.addCommentForm.get('discussion').value);
    input.append('avatar', this.discussionFile);
    input.append('todo_id', this.topicId);
    input.append('id', this.commentId)
    input.append('siteurl', location.origin)
    return input;
  }

  submitComment(event) {
    event.preventDefault();
    this.addCommentFormSubmitted = true;
    if (this.addCommentForm.invalid) {
      return;
    }
    const formModel = this.prepareSave();
      this.listServ.add_discussion(formModel)
      .subscribe(
        res => {
          this.getTopic()
          this.addCommentForm.reset;
          this.addCommentForm.patchValue({discussion:''});
          this.editCommentForm.patchValue({editdiscussion:''});
          this.clearAllContentEditor();
          this.addCommentFormSubmitted = false;
        },
        err => console.log(err)
      )
  }

  downloadFile(fileset, fileput) {
    return this.http
      .get('https://basecamp.imicreation.com/admin/public/api/v1/dfiles/'+fileset, {
        responseType: ResponseContentType.Blob,
      }).pipe(
        map(res => {
          return {
            filename: fileput,
            data: res.blob()
          };
        }))
      .subscribe(res => {
        var url = window.URL.createObjectURL(res.data);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = res.filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
      }, error => {
        console.log('download error:', JSON.stringify(error));
      }, () => {
        //console.log('Completed file download.')
      });
  }

  onFileChangeAvatar(event) {
    if (event.target.files.length > 0) {
      let file = event.target.files[0];
      this.editDiscussionFile = file
    }
  }

  enableModification(content, discussion_id){
    this.editCommentForm.patchValue({ editdiscussion: content });
    this.commentId = discussion_id;
    this.editor.setContent(content);
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      let file = event.target.files[0];
      this.discussionFile = file
    }
  }

  deleteDiscussion(comment){
    this.listServ.remove_discussion(comment)
    .toPromise().then(
      data=>{
        this.getTopic();
      },
      err=>console.log(err)
    )
  }

  clearAllContentEditor(){
    for(let i=0; i<tinymce.editors.length; i++){
       tinymce.editors[i].setContent("");
    }
   }

  
  
  editor;

  ngAfterViewInit() {
    tinymce.init({
      selector: "#editTopic, #addDiscussion, #editDiscussion",
      //plugins: ['link', 'table'],
      //skin_url: 'assets/skins/lightgray',
      setup: editor => {
        this.editor = editor;
        editor.on('keyup change', () => {
          const content = editor.getContent();
          this.editTopicForm.patchValue({topicContent:content});
          this.editCommentForm.patchValue({editdiscussion:content});
          this.addCommentForm.patchValue({discussion:content});
          //this.fieldData = content;
        });
      }
    });
  }

  editTopic(event){
    event.preventDefault();
    this.editTopicFormSubmitted = true;
    if (this.editTopicForm.invalid) {
      return;
    } console.log(this.editTopicForm.get('topicContent').value);
    const formData = this.editTopicForm.value;
    this.listServ.edit_topic({id:this.topicId, name:formData.topicName, content:this.editTopicForm.get('topicContent').value})
    .toPromise().then(
      res=>{
        this.getTopic();
      },
      err=>console.log(err)
    )
  }

  getTopic(){
    this.listServ.get_topic({topic_id:this.topicId})
    .toPromise().then(data=>{
      this.topic = data.topics;
      this.project = data.project;
      this.users = data.users;
      //this.discussions = data.comments;
      console.log(data);
    })
  }

  startEditingTopic(title, content){
    this.editor.setContent(content);
    this.editTopicForm.patchValue({topicName:title});
    this.editTopicForm.patchValue({topicContent:content});
  }

}
