import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getUserAvatar'
})
export class GetUserAvatarPipe implements PipeTransform {

  transform(value: any, users: any[]): any {
    for(let count=0; count<users.length; count++){
      let user_id = users[count].id;
      if(user_id===value){
        let user_img = users[count].avatar;
        if(user_img){
          return '<img src="https://basecamp.imicreation.com/admin/public/'+user_img+'" alt="users[count].name" class="rounded-circle"> ';
        } else{
          return '<span class="teamhqr-project-grid--nuser">'+users[count].name.charAt(0)+'</span> ';
        }
      }
    }
    return '<img src="https://basecamp.imicreation.com/admin/public/imi.jpg" alt="users[count].name" class="rounded-circle"> ';
  }

}
