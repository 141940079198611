import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fistLetter'
})
export class FistLetterPipe implements PipeTransform {

  transform(value: string): string {
    if(!value) return '';
    let newStr: string = "";
    newStr = value.charAt(0);
    return newStr;
  }

}
