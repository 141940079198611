import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ListsService} from '../lists.service';
declare var tinymce:any;
declare var jQuery:any;

@Component({
  selector: 'app-topics',
  templateUrl: './topics.component.html',
  styleUrls: ['./topics.component.css']
})
export class TopicsComponent implements OnInit {

  projectId = '';
  topicForm:FormGroup;
  topicFormSubmitted = false;
  topics:object = [];
  project;
  users;
  constructor(private route:ActivatedRoute, private listServ:ListsService, private _fb:FormBuilder) { 
    this.topicForm = this._fb.group({
      topicName:['', Validators.required],
      topicContent:['']
    })
  }

  editor;

  ngOnInit() {
    this.projectId = this.route.snapshot.paramMap.get("id"); //Get ID from URL, id is defined in app.routing file
    this.getTopics();
    window.scrollTo(1000,document.body.scrollHeight);
  }

  ngAfterViewInit() {
    tinymce.init({
      selector: "#addTopic",
      setup: editor => {
        this.editor = editor;
        editor.on('keyup change', () => {
          const content = editor.getContent();
          this.topicForm.patchValue({topicContent:content});
        });
      }
    });
  }

  ngOnDestroy() {
    tinymce.remove(this.editor);
  }

  get topicF(){
    return this.topicForm.controls;
  }

  addTopic(event){
    event.preventDefault();
    this.topicFormSubmitted = true;
    if(this.topicForm.invalid){
      return;
    }
    if(this.topicForm.valid){
      const topicData = this.topicForm.value;
      const topicFormData = {project_id:this.projectId, title:topicData.topicName, content:topicData.topicContent, siteurl:location.origin};
      this.listServ.add_topic(topicFormData)
      .toPromise().then(data=>{
        this.getTopics();
        jQuery("html, body").animate({ scrollTop: jQuery(document).height() }, 1000);
        this.topicForm.reset();
        this.editor.setContent('');
        this.topicFormSubmitted = false;
      })
    }
  }

  getTopics(){
    this.listServ.get_topics({project_id:this.projectId})
    .toPromise().then(data=>{
      this.topics = data.topics;
      this.project = data.project;
      this.users = data.users;
    })
  }

}
