import { Component, OnInit } from '@angular/core';
import {AuthService} from '../auth.service';
import {LoginnavService} from '../loginnav.service';
import {Router, ActivatedRoute} from '@angular/router';
import {LoaderService} from '../LoaderService';
import {FormBuilder, FormGroup, Validators, FormArray, FormControl} from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm:FormGroup
  loginSubmitted = false
  loginMsg = { class: '', msg: '' };
  loginUserData = {};
  stored_token: string;
  referer;
  constructor(private _auth: AuthService, public nav: LoginnavService, private _router: Router, public loaderService: LoaderService, private _fb:FormBuilder, private route:ActivatedRoute) { 
    this.loginForm = this._fb.group({
      loginEmail:['', [Validators.required, Validators.email]],
      loginPassword:['', [Validators.required]],
    })
  }

  get f() {
    return this.loginForm.controls
  }

  ngOnInit() {
    this.referer = this.route.snapshot.paramMap.get('referer');
  }

  loginUser() {
    this.loginSubmitted = true
    if (this.loginForm.invalid) {
      return;
    }
    let input = new FormData();
    input.append('email', this.loginForm.get('loginEmail').value);
    input.append('password', this.loginForm.get('loginPassword').value);
    this.loginMsg = { class: '', msg: '' };
    this._auth.loginUser(input)
      .subscribe(
        res => {
          this.loginMsg = { class: 'success', msg: res.message };
          this.loginForm.reset();
          //res => console.log(res)
          localStorage.setItem('improject_token', res.access_token)
          this.nav.hide();
          if(this.referer){
            this._router.navigate([this.referer]);
          } else{
            this._router.navigate(['/projects']);
          }
          
        },
        err => {
          /*console.log(err)
          let errors = '';
          console.log(err);
          for (const [key, value] of Object.entries(err.error.errors)) {
            errors += '<p>' + key + ': ' + value + '</p>';
          }*/
          console.log(err);
          this.loginMsg = { class: 'danger', msg: err.error.message };
        }
      )
    this.stored_token = localStorage.getItem('improject_token');
    if (this.stored_token !== '') {
      //this.nav.hide();
    }

  }

}
