import { Component, OnInit } from '@angular/core';
import {ListsService} from '../lists.service';
import { ActivatedRoute } from '@angular/router';
import {Http, ResponseContentType} from '@angular/http';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.css']
})
export class AttachmentsComponent implements OnInit {

  projectId;
  attachments = [];
  project;
  constructor(private listServ:ListsService, private route: ActivatedRoute, private http:Http) { }

  ngOnInit() {
    this.projectId = this.route.snapshot.paramMap.get("id");
    this.getAttachments();
  }

  getAttachments(){
    this.listServ.get_attachments({id:this.projectId})
    .toPromise().then(
      data=>{
        let lists = data['attachment1']['list'];
        this.project = data['project'];
        for(let count=0; count<lists.length; count++){
          let todos = lists[count]['todos'];
          if(todos.length){
            
            
            for(let tc=0; tc<todos.length; tc++){
              let comments = todos[tc]['discussions'];
              var todo_name = todos[tc]['name'];
              var todo_id = todos[tc]['id'];
              if(comments.length){
                for(let cc=0; cc<comments.length; cc++){
                  let attachment = comments[cc]['attachments'];
                  if(attachment.length){
                    this.attachments.push({date:attachment[0]['created_at'], file:attachment[0]['file'], attached:todo_name, type:'To-do', class:'success', url:todo_id, id:attachment[0]['id']});
                  }
                }
              }
            }
          }
        }
        let topics = data['attachment2'];
      },
      err=>console.log(err)
    )
  }

  downloadFile(fileset, fileput) {
    return this.http
      .get('https://basecamp.imicreation.com/admin/public/api/v1/dfiles/'+fileset, {
        responseType: ResponseContentType.Blob,
      }).pipe(
        map(res => {
          return {
            filename: fileput,
            data: res.blob()
          };
        }))
      .subscribe(res => {
        var url = window.URL.createObjectURL(res.data);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = res.filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
      }, error => {
        console.log('download error:', JSON.stringify(error));
      }, () => {
        //console.log('Completed file download.')
      });
  }

}
